import history from "./history";
import ReactGA from "react-ga";

class GA {
  constructor() {
    ReactGA.initialize("UA-147734674-1");
  }
  listen() {
    history.listen(({ pathname }) => {
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    });
  }
}

export default new GA();
