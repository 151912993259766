import React, { Component } from "react";
import classNames from "classnames";

export default class Input extends Component {
  componentWillMount() {
    this.inputRef = null;
  }

  deleteAll(e) {
    e.target.value = "";
    this.props.onChange && this.props.onChange(e);
    this.inputRef.value = "";
    this.inputRef.focus();
  }

  render() {
    const componentCls = classNames({
      "is-danger": this.props.errorMessage
    });

    return (
      <div className={`component my-input ${componentCls}`}>
        {(() => {
          switch (this.props.type) {
            case "search":
              return <i className="fas fa-search icon" />;
            case "heading":
              return <i className="fas fa-heading icon" />;
            case "link":
              return <i className="fas fa-link icon" />;
            default:
              return;
          }
        })()}
        <input
          placeholder={this.props.placeholder}
          onChange={e => {
            this.props.onChange && this.props.onChange(e);
          }}
          onInput={e => {
            this.props.onInput && this.props.onInput(e);
          }}
          ref={ref => (this.inputRef = ref)}
        />
        {(() => {
          if (this.props.errorMessage) {
            return <p className="help is-danger">{this.props.errorMessage}</p>;
          }
        })()}
        {(() => {
          if (this.props.value) {
            return (
              <div className="delete-button">
                <i
                  className="fas fa-times icon"
                  onClick={e => this.deleteAll(e)}
                ></i>
              </div>
            );
          }
        })()}
      </div>
    );
  }
}
