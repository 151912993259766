import React, { Component } from "react";
import PropTypes from "prop-types";

export class TableOfContents extends Component {
  static propTypes = {
    headingList: PropTypes.array
  };

  render() {
    return (
      <div className="table-of-contents-container">
        {(() => {
          if (this.props.headingList.length >= 3) {
            return (
              <div className="table-of-contents">
                <h3 className="heading">目次</h3>
                {this.props.headingList}
              </div>
            );
          }
        })()}
      </div>
    );
  }
}

export default TableOfContents;
