import React, { Component } from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import SnackBarPlugin from "../../plugins/snackbar";

/* styles */
const duration = 300;

const defaultStyle = {
  bottom: {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateX(0)"
  },
  top: {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateY(0)"
  }
};

const transitionStyles = {
  bottom: {
    entering: {
      transform: "translateX(0)"
    },
    entered: {
      transform: "translateX(0)"
    },
    exiting: {
      transform: "translateX(140%)"
    },
    exited: {
      transform: "translateX(140%)"
    }
  },
  top: {
    entering: {
      transform: "translateY(180%)"
    },
    entered: {
      transform: "translateY(180%)"
    },
    exiting: {
      transform: "translateY(0)"
    },
    exited: {
      transform: "translateY(0)"
    }
  }
};

class SnackBar extends Component {
  static propTypes = {
    text: PropTypes.string,
    position: PropTypes.string,
    global: PropTypes.bool,
    type: PropTypes.string
  };

  state = {
    show: false,
    text: ""
  };

  openSnackBar(text, timeout) {
    const _timeout = timeout || 5000;
    this.setState({
      show: true,
      text: text
    });
    if (timeout !== 0) {
      setTimeout(() => {
        this.closeSnackbar();
      }, _timeout);
    }
  }

  closeSnackbar() {
    this.setState({
      show: false
    });
  }

  componentWillMount = () => {
    if (this.props.global) {
      SnackBarPlugin.setComponent(this);
    }
  };

  render() {
    const position = this.props.position || "bottom";
    return (
      <Transition in={this.state.show} timeout={duration}>
        {state => (
          <div
            className={`component snack-bar ${position} ${this.props.type}`}
            style={{
              ...defaultStyle[position],
              ...transitionStyles[position][state]
            }}
          >
            {this.state.text}
          </div>
        )}
      </Transition>
    );
  }
}

export default SnackBar;
