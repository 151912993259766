import React, { Component } from "react";
import classNames from "classnames";

export default class Card extends Component {
  state = {
    altImageURL: ""
  };

  onDelete = e => {
    e.stopPropagation();
    const yesNo = window.confirm("このPinを削除しますか？");
    if (yesNo) {
      this.props.onDelete && this.props.onDelete();
    }
  };

  render() {
    const pinned = classNames({
      pinned: this.props.pinned
    });
    return (
      <div
        className={`component card clickable`}
        onClick={() => {
          this.props.onClick && this.props.onClick();
        }}
      >
        <div className="image">
          <img
            src={this.state.altImageURL || this.props.imageURL}
            alt="favicon"
            onError={() =>
              this.setState({
                altImageURL: "/img/link-icon.png"
              })
            }
          />
        </div>
        <div className="title">
          <h2 className="title">{this.props.title}</h2>
          {(() => {
            if (this.props.subtitle) {
              return <div className="subtitle">{this.props.subtitle}</div>;
            }
          })()}
        </div>
        {(() => {
          if (this.props.onDelete) {
            return (
              <div className="x-mark" onClick={this.onDelete}>
                <i className="fas fa-times icon"></i>
              </div>
            );
          }
        })()}
        {(() => {
          if (this.props.onPin) {
            return (
              <div
                className={`pin-mark ${pinned}`}
                onClick={e => {
                  this.props.onPin();
                  e.stopPropagation();
                }}
              >
                <i className="fas fa-thumbtack icon"></i>
              </div>
            );
          }
        })()}
      </div>
    );
  }
}
