import React from "react";
import PropTypes from "prop-types";
import SavingNotification from "../Ui/SavingNotification";
import { CharCounter, WordCounter, LineCounter } from "./Plugins";

class EditorFooter extends React.Component {
  static propTypes = {
    saving: PropTypes.bool,
    editorState: PropTypes.object
  };

  render() {
    return (
      <div className="editor-footer">
        <div className="char-counter">
          <CharCounter editorState={this.props.editorState} /> chars
        </div>
        <div className="word-counter">
          <WordCounter editorState={this.props.editorState} /> words
        </div>
        <div className="line-counter">
          <LineCounter editorState={this.props.editorState} /> lines
        </div>
        <SavingNotification
          className="saving-notification"
          saving={this.props.saving}
        />
      </div>
    );
  }
}

export default EditorFooter;
