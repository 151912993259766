import React, { Component } from "react";
import Input from "../Ui/Input";
import Firebase from "../../firebase";
import history from "../../history";
import SnackbarPlugin from "../../plugins/snackbar";

class BoardCreation extends Component {
  state = {
    boardName: "",
    errorForBoardName: ""
  };

  onCreateBoard() {
    const errorForBoardName = !this.state.boardName;
    this.setState({
      errorForBoardName: errorForBoardName
    });
    if (!errorForBoardName) {
      Firebase.createNewBoard(this.state.boardName).then(boardRef => {
        // 遷移
        this.props.onClose && this.props.onClose();
        setTimeout(() => {
          history.push(`/home/${boardRef.id}`);
        }, 1000);
        SnackbarPlugin.openSnackBar("ボードを作成しました");
      });
    }
  }

  render() {
    return (
      <div className="component board-creation">
        <div className="is-modal has-text-centered">
          <h1 className="title">新しいボードを作成</h1>
          <div className="control">
            <Input
              type="heading"
              value={this.state.boardName}
              onChange={e => {
                this.setState({ boardName: e.target.value });
              }}
              errorMessage={(() => {
                if (this.state.errorForBoardName) {
                  return "ボード名を入力してください";
                }
              })()}
              placeholder="ボード名を入力"
            />
          </div>
          <button
            className="button is-medium is-info create-board"
            onClick={() => {
              this.onCreateBoard();
            }}
          >
            作成
          </button>
        </div>
        <div
          className="close"
          onClick={() => {
            this.props.onClose && this.props.onClose();
          }}
        >
          <i className="fas fa-times icon" />
        </div>
      </div>
    );
  }
}

export default BoardCreation;
