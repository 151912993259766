import React from "react";
import { CompositeDecorator } from "draft-js";
import { FadeLoader } from "react-spinners";
import PropTypes from "prop-types";
import Immutable from "immutable";

class Anchor extends React.Component {
  static propTypes = {
    decoratedText: PropTypes.string,
    children: PropTypes.array
  };
  state = {
    card: false
  };

  constructor(props) {
    super();
    this.decoratedTextCache = props.decoratedText;
    this.event = null;
    this.setTimeout(true);
  }

  componentWillReceiveProps(props) {
    if (props.decoratedText !== this.decoratedTextCache) {
      this.setState({
        card: false
      });
      this.setTimeout();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.event);
  }

  setTimeout = immediate => {
    const timeout = immediate ? 0 : 5000;
    clearTimeout(this.event);
    this.event = setTimeout(() => {
      this.setState({
        card: true
      });
    }, timeout);
  };

  render() {
    if (this.state.card) {
      return (
        <div className="decorator-component card">
          <div className="card-content">
            <div className="content">
              <p
                className=""
                ref={ref => {
                  ref && ref.setAttribute("contenteditable", false);
                }}
              >
                Link to {this.props.decoratedText}
              </p>
              <a
                href={this.props.decoratedText}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.props.children}
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="decorator-component">
          {this.props.children}
          <div className="fade-loader">
            <FadeLoader></FadeLoader>
          </div>
        </div>
      );
    }
  }
}
const Link = props => {
  // インラインリンク
  return (
    <a
      href={props.decoratedText}
      ref={ref => {
        if (ref) {
          /* 親のDIVにクラスつけてCSS調整 */
          const parent = ref.parentNode;
          parent.classList.add("a-container");
        }
      }}
      onClick={e => {
        window.open(props.decoratedText);
        e.preventDefault();
      }}
      className="anchor"
    >
      {props.children}
    </a>
  );
};

const Storategy = {
  anchor: (contentBlock, callback, contentState) => {
    const regex = /^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/g;
    modifyContent(regex, contentBlock, callback);
  },
  link: (contentBlock, callback, contentState) => {
    // インラインリンク
    contentBlock.findEntityRanges(character => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === "LINK"
      );
    }, callback);
  }
  // horizontal: (contentBlock, callback, contentState) => {
  //   const regex = /---/g;
  //   modifyContent(regex, contentBlock, callback);
  // }
};

function modifyContent(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const Decorator = new CompositeDecorator([
  {
    strategy: Storategy.anchor,
    // component: Anchor
    component: Link
  },
  {
    strategy: Storategy.link,
    component: Link
  }
  // {
  //   strategy: Storategy.horizontal,
  //   component: Horizontal
  // }
]);

const BlockRenderMap = Immutable.Map({});

const BlockRenderers = {
  /* stateToHTML用のブロック要素レンダラー */
  divider: block => {
    return '<div class="hr"><br/></div>';
  }
};

function BlockStyleFn(contentBlock) {
  const type = contentBlock.getType();
  // console.log(type);
  switch (type) {
    case "header-one":
      return "title is-2";
    case "header-two":
      return "title is-4";
    case "header-three":
      return "title is-4";
    case "header-four":
      return "title is-5";
    default:
      return;
  }
}
function EntityStyleFn(entity) {
  const entityType = entity.get("type").toLowerCase();
  if (entityType === "link") {
    const data = entity.getData();
    return {
      element: "a",
      attributes: {
        href: data.url,
        target: "_blank"
      },
      style: {}
    };
  }
}

const StyleMap = {
  /* EXTEND BULMA */
  CODE: {
    backgroundColor: "whitesmoke",
    color: "#ff6666",
    fontSize: "0.875em",
    fontWeight: "normal",
    padding: "0.25em 0.5em 0.25em"
  },
  STRIKETHROUGH: {
    textDecoration: "line-through"
  },
  HIGHLIGHT: {
    background: "linear-gradient(transparent 0%, #ffff66 0%)"
  }
};
const StyleMapForHTML = {
  /* stateToHTML()のオプション用 */
  CODE: {
    style: {
      backgroundColor: "whitesmoke",
      color: "#ff6666",
      fontSize: "0.875em",
      fontWeight: "normal",
      padding: "0.25em 0.5em 0.25em"
    }
  },
  STRIKETHROUGH: {
    style: {
      textDecoration: "line-through"
    }
  },
  HIGHLIGHT: {
    element: "mark",
    style: {
      background: "linear-gradient(transparent 0%, #ffff66 0%)"
    }
  }
};

const CustomBlockFn = dom => {
  /* stateFromHTMLにおけるカスタムブロックスタイル復元用 */
  if (dom.classList.contains("hr")) {
    /* div.hr に対して水平線コンポーネントのタイプを付与 */
    return {
      type: "divider"
    };
  }
};

const CustomInlineFn = (dom, creator) => {
  /* stateFromHTMLにおけるカスタムインライン復元用 */
  if (dom.tagName === "MARK") {
    // ハイライト復元
    return creator.Style("HIGHLIGHT");
  }
};

export {
  /* Decotarotors */
  Decorator,
  BlockRenderMap,
  BlockRenderers,
  BlockStyleFn,
  EntityStyleFn,
  StyleMap,
  StyleMapForHTML,
  CustomBlockFn,
  CustomInlineFn,
  Anchor
};
