import React, { Component } from "react";
import PropTypes from "prop-types";

/* styles */

class SavingNotification extends Component {
  static propTypes = {
    saving: PropTypes.bool
  };
  constructor(props) {
    super(props);
    this.saved = false;
  }

  genMessage = () => {
    if (this.props.saving) {
      this.saved = true;
      return "保存中...";
    } else if (!this.saved) {
      return "";
    } else {
      return "保存しました";
    }
  };

  render() {
    return (
      <div className="saving-notification">
        <div className="message">{this.genMessage()}</div>
      </div>
    );
  }
}

export default SavingNotification;
