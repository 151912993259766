class SnackBarPlugin {
  constructor() {
    this.component = null;
  }

  setComponent = component => {
    this.component = component;
  };

  openSnackBar = (text, timeout) => {
    this.component.openSnackBar(text, timeout);
  };
}

export default new SnackBarPlugin();
