import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App";
// import { App } from "./containers"; // for redux
import { Router } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";

// import { Provider } from "react-redux";
// import store from "./redux/store";
import history from "./history";
import ga from "./ga";

ga.listen();

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
