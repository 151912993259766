import firebase from "firebase";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import history from "../history";

import Storage from "./storage";
import axios from "axios";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyCKGr47UX1k5jzu6F1aCVh5kOBjdP-3tbY",
  authDomain: "pinpin-5ff29.firebaseapp.com",
  databaseURL: "https://pinpin-5ff29.firebaseio.com",
  projectId: "pinpin-5ff29",
  storageBucket: "pinpin-5ff29.appspot.com",
  messagingSenderId: "569239072319",
  appId: "1:569239072319:web:dfdc5acb7179cf4a"
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this._onSnapshot = null;

    this.storage = app.storage();
    this.setModules();

    // const dbSettings = { timestampsInSnapshots: true };
    // this.db.settings(dbSettings);
  }

  setModules = () => {
    /* モジュール化されたfirebase部品の結合 */
    this.Storage = new Storage(this.storage);
  };

  /* AUTH */
  getUserRefThen(id, then, elseThen) {
    /* Repoto プロジェクトとの互換性のために実装 */
    const doc = this.db.collection("users").doc(id);
    doc.get().then(data => {
      if (data.exists) {
        then(doc, data);
      } else {
        elseThen(doc, data);
      }
    });
  }
  getCurrentUserRefThen(then, elseThen) {
    /* Repoto プロジェクトとの互換性のため実装 */
    const userId = this.get_currentUserId();
    if (userId) {
      /* ユーザーIDがあれば正常系*/
      this.getUserRefThen(userId, then, elseThen);
    } else {
      /* ユーザー登録されていなければユーザー検証待機 */
      this.auth.onAuthStateChanged(user => {
        if (user) {
          // ログイン済みであると判明
          this.getUserRefThen(user.uid, then, elseThen);
        } else {
          // 然もなくば..異常系
          elseThen && elseThen();
        }
      });
    }
  }
  get_currentUserId() {
    // firebase認証のカレントユーザー抜く
    if (this.auth.currentUser) {
      return this.auth.currentUser.uid;
    } else {
      return null;
    }
  }
  doCreateUserWithEmailAndPassword(email, password) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  doSignInWithEmailAndPassword(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  doSignInWithPopup(then, errorHandler) {
    var provider = new app.auth.GoogleAuthProvider();
    return this.auth
      .signInWithPopup(provider)
      .then(info => {
        var user = info.user;
        var token = info.credential.accessToken;
        then && then(user, token);
      })
      .catch(error => {
        // var errorCode = error.code;
        var errorMessage = error.message;
        // var email = error.email;
        // var credential = error.credential;
        console.log(errorMessage);
        errorHandler && errorHandler(error);
      });
  }
  doSignOut = then => {
    return this.auth.signOut().then(() => {
      then && then();
    });
  };
  doPasswordReset(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
  doPasswordUpdate(password) {
    return this.auth.currentUser.updatePassword(password);
  }

  unsubscribe() {
    this._onSnapshot && this._onSnapshot();
  }

  /* Board */
  genBoardRef(boardId) {
    return this.db.collection("board").doc(boardId);
  }
  getBoardDataThen(boardId, then, elseThen) {
    const ref = this.genBoardRef(boardId);
    ref.get().then(doc => {
      if (doc.exists) {
        const data = doc.data();
        data.id = doc.id;
        then(data);
      } else {
        elseThen && elseThen();
      }
    });
  }

  createNewBoard(boardName) {
    const ref = this.db.collection("board").doc();
    const userId = this.get_currentUserId();
    return new Promise(resolve => {
      return ref
        .set({
          boardName: boardName,
          allowList: [userId],
          owner: userId
        })
        .then(() => {
          this.getCurrentUserRefThen((_, doc) => {
            const data = doc.data();
            ref
              .collection("allowList")
              .doc(userId)
              .set({
                displayName: data.displayName,
                email: data.email
              })
              .then(() => {
                resolve(ref);
              });
          });
        });
    });
  }

  onSnapshotPins(boardId, then, elseThen) {
    const doc = this.genBoardRef(boardId);

    this.unsubscribe();
    this._onSnapshot = doc.collection("pins").onSnapshot(qs => {
      if (qs.empty) {
        elseThen && elseThen();
      } else {
        const pins = [];
        qs.forEach(doc => {
          const data = doc.data();
          data.id = doc.id;
          pins.push(data);
        });
        then(pins);
      }
    });
  }
  getPinsThen(boardId, then, elseThen) {
    const boardRef = this.genBoardRef(boardId);

    boardRef
      .collection("pins")
      .get()
      .then(qs => {
        if (qs.empty) {
          elseThen && elseThen();
        } else {
          const pins = [];
          qs.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            pins.push(data);
          });
          then(pins);
        }
      });
  }

  getShareRef(shareId) {
    return this.db.collection("share").doc(shareId);
  }

  genShareRef(boardId, title) {
    const boardRef = this.genBoardRef(boardId);
    const shareRef = this.db.collection("share").doc();
    shareRef.set({
      boardRef: boardRef
    });
    boardRef.update({
      shareRef: shareRef
    });

    return new Promise(resolve => {
      return resolve(shareRef);
    });
  }

  requestBoardShareThen(boardRef, then) {
    this.getCurrentUserRefThen((ref, doc) => {
      const shareUserRef = boardRef.collection("shareRequestUsers").doc(ref.id);
      const data = doc.data();
      shareUserRef.set({
        displayName: data.displayName,
        email: data.email
      });
      // ボードを追加
      boardRef.get().then(_doc => {
        ref
          .collection("boardList")
          .doc(boardRef.id)
          .set({
            boardName: _doc.data().boardName
          });
      });
      then && then();
    });
  }

  /* Pin */
  extractDomainName = url => url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1];
  getPinRef(boardId, pinId) {
    return this.genBoardRef(boardId)
      .collection("pins")
      .doc(pinId);
  }

  checkPresetFavicon(url) {
    /* プリセットのファビコンがあればそれを採用 */
    if (url.startsWith("https://docs.google.com/spreadsheets")) {
      return "/img/favicons/googlespreadsheet.png";
    } else if (url.startsWith("https://docs.google.com/presentation")) {
      return "/img/favicons/googleslides.png";
    } else if (url.startsWith("https://docs.google.com/document")) {
      return "/img/favicons/googledocument.png";
    } else if (url.startsWith("https://analytics.google.com")) {
      return "/img/favicons/googleanalytics.jpg";
    } else if (url.startsWith("https://console.firebase.google.com")) {
      return "/img/favicons/firebase.png";
    } else if (url.startsWith("https://docs.google.com/forms")) {
      return "/img/favicons/googleform.svg";
    }
  }

  createUrlPin(boardId, data) {
    /* for Development */
    const urlDomain = this.extractDomainName(data.url);
    const faviconURL = `http://${urlDomain}/favicon.ico`;

    const doc = this.genBoardRef(boardId)
      .collection("pins")
      .doc();
    const timestamp = this.createTimestamp();
    doc.set({
      url: data.url,
      // heading: data.heading,
      heading: "...",
      createdAt: timestamp,
      browsingHistory: [timestamp],
      faviconURL: faviconURL,
      type: "url"
    });

    // タイトル取得
    const endPoint = "https://url-meta-deta.herokuapp.com/url";
    const token = "jcoiadsjanmchopnh9niNNFHSD)(NYC()0Nhnfha";
    const param = encodeURIComponent(data.url);
    axios.get(`${endPoint}/${param}?token=${token}`).then(res => {
      const heading = res.data.data.title;
      console.log(res);
      doc.update({
        heading: heading
      });
    });

    // ファビコン取得
    const presetFaviconURL = this.checkPresetFavicon(data.url);
    if (presetFaviconURL) {
      doc.update({
        faviconURL: presetFaviconURL
      });
    } else {
      const faviconAPIURL = `https://favicongrabber.com/api/grab/${urlDomain}`;
      axios.get(faviconAPIURL).then(resp => {
        const icons = resp.data.icons;
        if (icons.length) {
          const iconURL = icons[0].src;
          doc.update({
            faviconURL: iconURL
          });
        }
      });
    }

    return new Promise(resolve => {
      resolve(doc);
    });
  }

  createDocumentPin(boardId, data) {
    const doc = this.genBoardRef(boardId)
      .collection("pins")
      .doc();
    const timestamp = this.createTimestamp();
    doc.set({
      heading: data.heading,
      documentRef: data.documentRef,
      documentId: data.documentId,
      createdAt: timestamp,
      faviconURL: "/img/document.png",
      browsingHistory: [timestamp],
      type: "document"
    });

    return new Promise(resolve => {
      resolve(doc);
    });
  }

  deletePin(boardId, doc_id) {
    return this.genBoardRef(boardId)
      .collection("pins")
      .doc(doc_id)
      .delete();
  }

  doPin(boardId, doc_id, _boolean) {
    const doc = this.getPinRef(boardId, doc_id);
    doc.update({
      pin: _boolean
    });
    return new Promise(resolve => {
      resolve(doc);
    });
  }

  pushBrowsingHistory(boardId, pinId) {
    const doc = this.getPinRef(boardId, pinId);
    doc.get().then(_doc => {
      const data = _doc.data();
      const browsingHistory = data.browsingHistory;
      browsingHistory.unshift(this.createTimestamp());
      if (browsingHistory.length > 5) {
        browsingHistory.length = 5;
      }
      doc.update({
        browsingHistory: browsingHistory
      });
    });
  }

  /* Articles */
  getArticleRef(boardId, articleId) {
    const boardRef = this.genBoardRef(boardId);
    if (articleId) {
      return boardRef.collection("articles").doc(articleId);
    } else {
      return boardRef.collection("articles").doc();
    }
  }

  /* Utility */
  forEach(ref, forThen /*limit*/) {
    return ref.get().then(qs => {
      qs.forEach(forThen);
    });
  }
  getData(ref, then, elseThen) {
    return ref
      .get()
      .then(doc => {
        if (doc.exists) {
          then(doc.data(), doc);
        } else {
          elseThen({ history: history });
        }
      })
      .catch(() => {
        elseThen({ history: history });
      });
  }
  onSnapshot(ref, then, elseThen) {
    return ref.onSnapshot(doc => {
      if (doc.exists) {
        then(doc.data(), doc);
      } else {
        elseThen({ history: history });
      }
    });
  }

  /* Store API */
  qsRef2doc(collection, qsRef) {
    return this.db.collection(collection).doc(qsRef.id);
  }

  /* Generator */
  createIncrement(increment = 1) {
    return app.firestore.FieldValue.increment(increment);
  }

  createDecrement(decrement = 1) {
    return app.firestore.FieldValue.increment(-decrement);
  }

  createTimestamp() {
    return firebase.firestore.Timestamp.now();
  }
}

export default new Firebase();
