import uuidv4 from "uuid/v4";
import axios from "axios";

export default class {
  constructor(storage) {
    this.storage = storage;
  }

  genUserIconRef = uuid => {
    uuid = uuid || uuidv4();
    return this.storage.ref("users/icon").child(uuid);
  };

  genArticleImageRef = uuid => {
    uuid = uuid || uuidv4();
    return this.storage.ref("article/images").child(uuid);
  };

  uploadFile = (ref, file, then) => {
    ref.put(file).then(() => {
      then && then();
    });
  };

  uploadFromUrl = (ref, url, then) => {
    /* URLから画像をダウンロードして、それをそのままstorageにアップロードする */
    axios({
      url: url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      const blob = new Blob([response.data]);
      this.uploadFile(ref, blob, then);
    });
  };
}
