import React from "react";
class Modules {
  removeHtmlTag = text => {
    /* 文字列からHTMLタグを決して返す */
    return text.replace(/<(".*?"|'.*?'|[^'"])*?>/g, "");
  };
  newline2br = text => {
    return text.split("\n").map((item, index) => {
      return (
        <span key={index}>
          {item}
          <br />
        </span>
      );
    });
  };
}

export default new Modules();
