import React, { Component } from "react";
import Input from "../Ui/Input";
import Card from "../Card";
import PinCreation from "../Overlay/PinCreation";
import BoardCreation from "../Overlay/BoardCreation";
import Firebase from "../../firebase";
import { withRouter } from "react-router";
import history from "../../history";
import SnackBar from "../Ui/SnackBar";
import SnackBarPlugin from "../../plugins/snackbar";
import snackbar from "../../plugins/snackbar";
import Loader from "../../components/Ui/Loader";

class Home extends Component {
  state = {
    boardName: "",
    creationMode: false,
    boardCreationMode: false,
    pins: [],
    searchedPins: null,
    loading: false,
    unAuthorizedShareRequest: []
  };

  createURLPin = () => {
    this.setState({
      creationMode: true
    });
  };

  createDocumentPin() {
    history.push({
      pathname: `/edit/${this.getBoardId()}/new/`
      // search: `?title=k`
    });
  }

  getBoardId(props) {
    return props ? props.match.params.id : this.props.match.params.id;
  }

  componentWillReceiveProps = props => {
    /* 同一URL内でのボード遷移に対応 */
    const boardId = this.getBoardId(props);
    if (this.getBoardId() !== boardId) {
      if (boardId !== "new") {
        this.setState({ loading: true });
      }
      this.initBoard(boardId);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };

  componentWillMount() {
    this.initBoard(this.getBoardId());
  }

  initBoard(boardId) {
    this.setState({
      boardName: "",
      creationMode: false,
      pins: [],
      searchedPins: null
    });

    if (boardId === "new") {
      this.setState({
        boardCreationMode: true
      });
    } else {
      Firebase.getBoardDataThen(
        boardId,
        boardData => {
          this.setState({
            boardName: boardData.boardName
          });
          Firebase.onSnapshotPins(
            this.getBoardId(),
            pins => {
              pins = this.sortPins(pins);
              this.setState({ pins: pins });
            },
            e => {
              /* 異常系 */
              console.log(e);
            }
          );

          const boardRef = Firebase.genBoardRef(this.getBoardId());
          const unAuthorizedShareRequest = [];
          Firebase.forEach(boardRef.collection("shareRequestUsers"), doc => {
            unAuthorizedShareRequest.push(doc);
          })
            .then(() => {
              this.setState({
                unAuthorizedShareRequest: unAuthorizedShareRequest
              });
              Firebase.getCurrentUserRefThen(userRef => {
                userRef.update({
                  lastBoardId: this.getBoardId()
                });
                userRef
                  .collection("boardList")
                  .doc(boardId)
                  .set({
                    boardName: boardData.boardName
                  });
              });
            })
            .catch(e => {
              SnackBarPlugin.openSnackBar("閲覧権限がありません");
              history.push("/");
              Firebase.getCurrentUserRefThen(userRef => {
                userRef.update({
                  lastBoardId: null
                });
              });
            });
        },
        () => {
          Firebase.getCurrentUserRefThen(userRef => {
            userRef.update({
              lastBoardId: null
            });
            history.push("/notfound");
          });
        }
      );
    }
  }

  sortPins(pins) {
    /* ソートする */
    const now = Firebase.createTimestamp().toDate();
    // 閲覧履歴から優先度を点数化する
    const priorityPoints = {};
    pins.forEach(pin => {
      var sum = 0;
      pin.browsingHistory.forEach((timestamp, index) => {
        const diff = now - timestamp.toDate();
        sum += diff * (5 - index);
      });
      priorityPoints[pin.id] = sum;
    });

    // ソート
    return pins.sort((prev, next) => {
      return priorityPoints[prev.id] - priorityPoints[next.id];
    });
  }

  componentWillUnmount() {
    /* Pin監視ルーチンの停止 */
    Firebase.unsubscribe();
  }

  deleteCard(id) {
    Firebase.deletePin(this.getBoardId(), id).then(() => {
      SnackBarPlugin.openSnackBar("PINを削除しました");
    });
  }

  pinCard(id, _bool) {
    Firebase.doPin(this.getBoardId(), id, _bool).then(() => {
      SnackBarPlugin.openSnackBar(_bool ? "PINしました" : "PINを外しました");
    });
  }

  doSearch(value) {
    if (!value) {
      this.setState({
        searchedPins: null
      });
    } else {
      const searchedPins = this.state.pins.filter(pin => {
        if (pin.heading.toLowerCase().match(value.toLowerCase())) {
          return true;
        } else if (
          pin.url &&
          pin.url.toLowerCase().match(value.toLowerCase())
        ) {
          return true;
        }
      });
      this.setState({
        searchedPins: searchedPins
      });
    }
  }

  goToDocument(data) {
    Firebase.pushBrowsingHistory(this.getBoardId(), data.id);
    const documentId = data.documentId;
    history.push(`/edit/${this.getBoardId()}/${documentId}`);
  }
  goToLink(data) {
    Firebase.pushBrowsingHistory(this.getBoardId(), data.id);
    window.open(data.url, "_blank");
  }
  goToSettings() {
    history.push(`/settings/${this.getBoardId()}`);
  }

  shareBoard() {
    // ボード共有URLの生成
    const copyShareURL = shareRef => {
      const shareId = shareRef.id;
      const shareURL = `${window.location.host}/share/${shareId}`;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(shareURL);
      }
      SnackBarPlugin.openSnackBar("共有URLをクリップボードにコピーしました");
    };

    const ref = Firebase.genBoardRef(this.getBoardId());
    ref.get().then(doc => {
      const shareRef = doc.data().shareRef;
      if (shareRef) {
        copyShareURL(shareRef);
      } else {
        Firebase.genShareRef(this.getBoardId(), this.state.boardName).then(
          shareRef => {
            copyShareURL(shareRef);
          }
        );
      }
    });
  }

  render() {
    const _pins =
      this.state.searchedPins !== null
        ? this.state.searchedPins
        : this.state.pins;
    const sortedPins = _pins.slice().sort((pre, after) => {
      if (pre.pin && !after.pin) {
        return -1;
      }
      return 0;
    });

    return (
      <div className="page home">
        <div className="bg"></div>

        {/* Heading */}
        <div className="heading">
          <div className="board-name">
            <h1 className="subtitle">{this.state.boardName}</h1>
          </div>
          <div className="search-input">
            <Input
              placeholder="ボードを検索"
              type="search"
              onInput={e => {
                this.doSearch(e.target.value);
              }}
              onChange={e => {
                this.doSearch(e.target.value);
              }}
              value={this.state.searchedPins}
            />
          </div>
          <div className="configration">
            <i
              className="fas fa-cogs icon"
              onClick={() => {
                this.goToSettings();
              }}
            />
            {(() => {
              if (this.state.unAuthorizedShareRequest.length) {
                return (
                  <div className="tag badge is-danger">
                    {this.state.unAuthorizedShareRequest.length}
                  </div>
                );
              }
            })()}
          </div>
        </div>

        {/* Pins */}
        <div className="pins">
          <div className="cards left">
            <div className="cards-header">
              <img src="/img/thumbtack.png" className="pin-icon"></img>
              <h1 className="title">最近のPin</h1>
            </div>
            {(() => {
              return sortedPins.map(data => (
                <Card
                  title={data.heading || "無題"}
                  subtitle={data.url || "ドキュメント"}
                  imageURL={data.faviconURL}
                  onClick={() => {
                    if (data.type === "url") {
                      this.goToLink(data);
                    } else if (data.type === "document") {
                      this.goToDocument(data);
                    }
                  }}
                  onDelete={() => {
                    this.deleteCard(data.id);
                  }}
                  onPin={() => {
                    this.pinCard(data.id, !data.pin);
                  }}
                  pinned={data.pin}
                />
              ));
            })()}
          </div>
          <div className="cards center">
            <div className="cards-header">
              <img src="/img/document.png" className="document-icon"></img>
              <h1 className="title">ドキュメント</h1>
              <img
                src="/img/plus.png"
                className="add-pin"
                onClick={() => this.createDocumentPin()}
              ></img>
            </div>
            {(() => {
              const pins =
                this.state.searchedPins !== null
                  ? this.state.searchedPins
                  : this.state.pins;
              return pins.map(data => {
                if (data.type === "document") {
                  return (
                    <Card
                      title={data.heading || "無題"}
                      subtitle={data.url || "ドキュメント"}
                      imageURL={data.faviconURL}
                      onClick={() => {
                        if (data.type === "url") {
                          this.goToLink(data);
                        } else if (data.type === "document") {
                          this.goToDocument(data);
                        }
                      }}
                      onDelete={() => {
                        this.deleteCard(data.id);
                      }}
                      onPin={() => {
                        this.pinCard(data.id, !data.pin);
                      }}
                      pinned={data.pin}
                    />
                  );
                }
              });
            })()}
            <Card
              title="pinを追加"
              onClick={() => {
                this.createDocumentPin();
              }}
              imageURL="/img/plus.png"
            />
          </div>
          <div className="cards right">
            <div className="cards-header">
              <img src="/img/link-icon.png" className="link-icon"></img>
              <h1 className="title">URL</h1>
              <img
                src="/img/plus.png"
                className="add-pin"
                onClick={() => this.createURLPin()}
              ></img>
            </div>
            {(() => {
              const pins =
                this.state.searchedPins !== null
                  ? this.state.searchedPins
                  : this.state.pins;
              return pins.map(data => {
                if (data.type === "url") {
                  return (
                    <Card
                      title={data.heading}
                      subtitle={data.url || "ドキュメント"}
                      imageURL={data.faviconURL}
                      onClick={() => {
                        if (data.type === "url") {
                          this.goToLink(data);
                        } else if (data.type === "document") {
                          this.goToDocument(data);
                        }
                      }}
                      onDelete={() => {
                        this.deleteCard(data.id);
                      }}
                      onPin={() => {
                        this.pinCard(data.id, !data.pin);
                      }}
                      pinned={data.pin}
                    />
                  );
                }
              });
            })()}
            <Card
              title="pinを追加"
              onClick={() => {
                this.createURLPin();
              }}
              imageURL="/img/plus.png"
            />
          </div>
        </div>

        {/* Meta Buttons */}
        <div className="meta-buttons">
          <button
            className="link-button button tooltip"
            data-tooltip="ボードの共有"
            onClick={() => this.shareBoard()}
          >
            <i className="fas fa-share-alt icon" />
          </button>
        </div>

        <PinCreation
          onClose={() =>
            this.setState({
              creationMode: false
            })
          }
          show={this.state.creationMode}
        />
        {/* Creation Mode */}
        {(() => {
          if (this.state.boardCreationMode) {
            return (
              <BoardCreation
                onClose={() => {
                  history.goBack();
                  this.setState({
                    boardCreationMode: false
                  });
                }}
              ></BoardCreation>
            );
          }
        })()}
        {(() => {
          if (this.state.loading) {
            return (
              <Loader loading={this.state.loading} overlay={true}></Loader>
            );
          }
        })()}
      </div>
    );
  }
}

export default withRouter(Home);
