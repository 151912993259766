import React, { Component } from "react";
import history from "../../history";
import Firebase from "../../firebase";
import Snackbar from "../../plugins/snackbar";

export default class Landing extends Component {
  doLogin() {
    Snackbar.openSnackBar("Googleアカウントでログイン");
    Firebase.doSignInWithPopup(user => {
      Snackbar.openSnackBar(`ようこそ ${user.displayName}さん`);
      // ログイン成功時
      Firebase.getCurrentUserRefThen(
        (ref, data) => {
          // すでに登録が済んでいる
          if (data.lastBoardId) {
            history.push(`/home/${data.lastBoardId}`);
          }
        },
        ref => {
          console.log("Create User...");
          // 初回ログイン
          ref.set({
            uid: user.uid,
            displayName: user.displayName,
            email: user.email
          });

          // ボード生成
          Firebase.createNewBoard(`${user.displayName}のボード`).then(
            boardRef => {
              // 遷移
              history.push(`/home/${boardRef.id}`);
            }
          );
        }
      );
    });
  }

  render() {
    return (
      <div className="page landing">
        <section className="hero is-fullheight top">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">
                <span className="is-bold">PinPin</span>
              </h1>
              <h2 className="subtitle">チームで使えるWEBコルクボード</h2>
            </div>
            <div className="credit">
              Photo by
              <a
                href="https://unsplash.com/@sibilant?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                target="_blank"
              >
                Josephine Bredehoft
              </a>
              on
              <a href="https://unsplash.com/" target="_blank">
                Unsplash
              </a>
            </div>
          </div>
        </section>
        <section className="hero is-medium">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">
                <span className="is-bold">PinPin</span>とは？
              </h1>
              <h2 className="subtitle readable">
                URLや議事録などを手軽に "ピン"
                して共有できる、WEBコルクボードです。
              </h2>
            </div>
          </div>
        </section>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="button-area flex center">
                <button
                  className="button main-button is-large is-info"
                  onClick={this.doLogin}
                >
                  コルクボードをためす
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="hero has-background-white-ter is-medium">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">つかいかた</h1>
              <div className="usage">
                <ol className="step">
                  <li className="step">ボードをつくる</li>
                  <li className="step">
                    ボードにリンクをPINしたりドキュメントを作成する
                  </li>
                  <li className="step">ボードを共有する</li>
                </ol>
              </div>
              <div className="button-area flex center">
                <button
                  className="button main-button is-large"
                  onClick={() => {
                    const url = `/edit/IzCc1V2KqERSkZcT8YdY/3uiXT3oQwGtMEGR2e1dO/?usp=sharing`;
                    window.open(url);
                  }}
                >
                  <i class="far fa-question-circle icon"></i>
                  PinPinについて詳しく
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-medium">
          <div className="hero-body">
            <div className="container">
              <h1 className="title has-text-centered is-3">
                早速ボードを作成してみましょう
              </h1>
              <div className="button-area flex center">
                <button
                  className="button main-button is-large is-info"
                  onClick={this.doLogin}
                >
                  <i class="far fa-clipboard icon"></i> ボードを作成
                </button>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="content has-text-centered">
            <p>
              <strong>PinPin</strong> by
              <span>Yuma Kaminaga</span>
            </p>
            <p>©2019 Yuma kaminaga All rights reserved.</p>
          </div>
          <div className="content has-text-centered">
            <a className="" href="/terms" target="_blank">
              利用規約
            </a>
          </div>
        </footer>
      </div>
    );
  }
}
