import React, { Component } from "react";
import history from "../../history";

class Share extends Component {
  state = {
    boardName: "OK"
  };

  goHome() {
    history.push("/");
  }

  render() {
    return (
      <div className="page component share-request">
        <section className="hero is-medium">
          <div className="hero-body has-text-centered">
            <div className="container">
              <h1 className="title">
                共有ボード「{this.state.boardName}」に参加申請を送りました
              </h1>
              <h2 className="subtitle">
                申請が許可されるとボードへアクセスできます
              </h2>
            </div>
          </div>
        </section>
        <section className="hero is-medium">
          <div className="hero-body has-text-centered">
            <div className="container">
              <button
                className="button is-large"
                onClick={() => {
                  this.goHome();
                }}
              >
                ホームへ戻る
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Share;
