import React, { Component } from "react";
import snackBar from "../../plugins/snackbar";
import Loader from "../Ui/Loader";
import history from "../../history";
import Firebase from "../../firebase";
import SnackBar from "../Ui/SnackBar";
import SnackBarPlugin from "../../plugins/snackbar";

class Share extends Component {
  state = {
    boardName: "",
    shareRef: null,
    boardRef: null,
    loading: true
  };

  getShareId(props) {
    return props ? props.match.params.id : this.props.match.params.id;
  }

  componentWillMount() {
    const shareId = this.getShareId();
    const uid = Firebase.get_currentUserId();
    if (shareId) {
      const shareRef = Firebase.getShareRef(shareId);
      shareRef.get().then(share => {
        if (share.exists) {
          const boardRef = share.data().boardRef;
          // 認証済みチェック
          boardRef.get().then(boardDoc => {
            const boardData = boardDoc.data();
            if (boardData.allowList.includes(uid)) {
              history.push(`/home/${boardRef.id}`);
            } else {
              setTimeout(() => {
                this.setState({
                  shareRef: shareRef,
                  boardRef: boardRef,
                  boardName: boardData.boardName,
                  loading: false
                });
              }, 1000);
            }
          });
        } else {
          history.push("/not-found");
        }
      });
    }
  }

  doRequest() {
    /* リクエストの追加 */
    const doRequest = () => {
      this.setState({
        loading: true
      });
      // 認証済みチェック
      const uid = Firebase.get_currentUserId();
      this.state.boardRef.get().then(boardDoc => {
        const boardData = boardDoc.data();
        if (boardData.allowList.includes(uid)) {
          history.push(`/home/${this.state.boardRef.id}`);
        } else {
          Firebase.requestBoardShareThen(this.state.boardRef, () => {
            setTimeout(() => {
              history.push("/share/done");
            }, 2000);
          });
        }
      });
    };

    Firebase.getCurrentUserRefThen(doRequest, () => {
      SnackBarPlugin.openSnackBar("Googleアカウントでログイン");
      Firebase.doSignInWithPopup(doRequest);
    });
  }

  render() {
    return (
      <div className="page component share-request">
        {(() => {
          if (this.state.shareRef) {
            return (
              <div>
                <section className="hero is-medium">
                  <div className="hero-body has-text-centered">
                    <div className="container">
                      <h1 className="title">
                        共有ボード「{this.state.boardName}」に参加しましょう
                      </h1>
                      <h2 className="subtitle">
                        ボードへの参加申請後、申請が許可されるとボードへアクセスできます
                      </h2>
                    </div>
                  </div>
                </section>
                <section className="hero is-medium">
                  <div className="hero-body has-text-centered">
                    <div className="container">
                      <button
                        className="button is-large"
                        onClick={() => {
                          this.doRequest();
                        }}
                      >
                        ボードへの参加申請をする
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            );
          }
        })()}
        <Loader loading={this.state.loading} overlay={true}></Loader>
      </div>
    );
  }
}

export default Share;
