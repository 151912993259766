import React from "react";
import createInlineToolbarPlugin from "draft-js-inline-toolbar-plugin";
// import {Separator} from "draft-js-inline-toolbar-plugin";
import createSideToolbarPlugin from "draft-js-side-toolbar-plugin";
import createMarkdownPlugin from "draft-js-markdown-plugin";
import createFocusPlugin from "draft-js-focus-plugin";
import Prism from "prismjs";
import createPrismPlugin from "draft-js-prism-plugin";
import createLinkPlugin from "draft-js-anchor-plugin";
import createImagePlugin from "draft-js-image-plugin";
import createCounterPlugin from "draft-js-counter-plugin";
import composeDecorators from "draft-js-plugins-editor/lib/utils/composeDecorators";
import createDividerPlugin from "draft-js-divider-plugin";
import FileUploader from "../../plugins/file-uploader";
import Firebase from "../../firebase";
import createBlockDndPlugin from "draft-js-drag-n-drop-plugin";
import createDragNDropUploadPlugin from "@mikeljames/draft-js-drag-n-drop-upload-plugin";

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton
  // CodeBlockButton
} from "draft-js-buttons";
import {
  createBlockStyleButton,
  createInlineStyleButton
} from "draft-js-buttons";

/* Initialize Plugins */
const FocusPlugin = createFocusPlugin();
const BlockDndPlugin = createBlockDndPlugin();
const FocusDecorator = composeDecorators(
  FocusPlugin.decorator,
  BlockDndPlugin.decorator
);
const InlineToolbarPlugin = createInlineToolbarPlugin();
const SideToolbarPlugin = createSideToolbarPlugin();
const PrismPlugin = createPrismPlugin({ prism: Prism });
const MarkdownPlugin = createMarkdownPlugin();
const ImagePlugin = createImagePlugin({ decorator: FocusDecorator });
const CounterPlugin = createCounterPlugin();
const DividerPlugin = createDividerPlugin({ decorator: FocusDecorator });

const LinkPlugin = createLinkPlugin({
  placeholder: "https://…"
});
const DragNDropFileUploadPlugin = createDragNDropUploadPlugin({
  // handleUpload: (...args) => {
  //   console.log(args);
  // },
  // addImage: ImagePlugin.addImage
});

/* Components */
const { SideToolbar } = SideToolbarPlugin;
const { InlineToolbar } = InlineToolbarPlugin;
const {
  CharCounter,
  WordCounter,
  LineCounter /*CustomCounter*/
} = CounterPlugin;

const EditorPlugins = [
  InlineToolbarPlugin,
  SideToolbarPlugin,
  PrismPlugin,
  MarkdownPlugin,
  FocusPlugin,
  LinkPlugin,
  ImagePlugin,
  CounterPlugin,
  DragNDropFileUploadPlugin,
  BlockDndPlugin,
  DividerPlugin
];

const StrikethrowButton = createInlineStyleButton({
  style: "STRIKETHROUGH",
  children: (
    <div>
      <i className="fas fa-strikethrough" />
    </div>
  )
});
const HighlightButton = createInlineStyleButton({
  style: "HIGHLIGHT",
  children: (
    <div>
      <i className="fas fa-highlighter" />
    </div>
  )
});

function renderInlineToolbarButtons(externalProps) {
  return (
    <div>
      {/* <HeadlinesButton {...externalProps} /> 
      <HeadlineOneButton {...externalProps} />
      <HeadlineTwoButton {...externalProps} />
      <HeadlineThreeButton {...externalProps} /> */}
      <BoldButton {...externalProps} />
      <ItalicButton {...externalProps} />
      <UnderlineButton {...externalProps} />
      <StrikethrowButton {...externalProps} />
      <HighlightButton {...externalProps} />
      <CodeButton {...externalProps} />
      {/* <Separator {...externalProps} /> */}
      <BlockquoteButton {...externalProps} />
      <LinkPlugin.LinkButton {...externalProps} />
      {/* <CodeBlockButton {...externalProps} /> */}
    </div>
  );
}

const HeadlineFourButton = createBlockStyleButton({
  blockType: "header-four",
  children: "H4"
});

class _ImagePluginManager {
  constructor() {
    this.vm = null;
  }

  setContext = vm => {
    this.vm = vm;
  };

  prompt = () => {
    /* 画像のアップロード */
    FileUploader.doUpload(files => {
      const ref = Firebase.Storage.genArticleImageRef();
      Firebase.Storage.uploadFile(ref, files[0], () => {
        ref.getDownloadURL().then(url => {
          this.addImage(url);
        });
      });
    });
  };

  addImage = url => {
    setTimeout(() => {
      this.vm.onChange(ImagePlugin.addImage(this.vm.state.editorState, url));
    }, 1000);
  };
}
const ImagePluginManager = new _ImagePluginManager();

const AddImageButton = createBlockStyleButton({
  blockType: "",
  children: (
    <div onClick={ImagePluginManager.prompt}>
      <i className="far fa-image" />
    </div>
  )
});

const { DividerButton } = DividerPlugin;

function renderSideToolbarButtons(externalProps) {
  return (
    <div>
      <HeadlineOneButton {...externalProps} />
      <HeadlineTwoButton {...externalProps} />
      <HeadlineThreeButton {...externalProps} />
      <HeadlineFourButton {...externalProps} />
      <UnorderedListButton {...externalProps} />
      <OrderedListButton {...externalProps} />
      <AddImageButton {...externalProps} />
      <DividerButton {...externalProps} />
    </div>
  );
}

export {
  EditorPlugins,
  SideToolbar,
  InlineToolbar,
  renderSideToolbarButtons,
  renderInlineToolbarButtons,
  ImagePluginManager,
  CharCounter,
  WordCounter,
  LineCounter
};
