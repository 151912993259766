import React from "react";
import FileUploaderPlugin from "../../plugins/file-uploader";

const InputStyle = {
  position: "absolute",
  display: "none"
};

class FileUploader extends React.Component {
  render() {
    return (
      <div>
        <input
          type="file"
          accept="image/*"
          ref={FileUploaderPlugin.setRef}
          style={InputStyle}
          onChange={FileUploaderPlugin.onChange}
        />
      </div>
    );
  }
}

export default FileUploader;
