import React from "react";
import { withRouter } from "react-router-dom";
import history from "../../history";
import SnackBarPlugin from "../../plugins/snackbar";

class NotFound extends React.Component {
  componentWillMount() {
    history.push("/"); // リダイレクト
    setTimeout(() => {
      SnackBarPlugin.openSnackBar("ページが見つかりませんでした");
    }, 1000);
  }

  render() {
    return <div />;
  }
}

export default withRouter(NotFound);
