import React from "react";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";
import PropTypes from "prop-types";

import Header from "../Navigation/Header";
import Landing from "../Landing";
import Home from "../Home";
import Settings from "../Home/Setting";
import Editor from "../Edit/Editor";
import Share from "../Share";
import ShareDone from "../Share/done";
// import SignIn from "../SignIn";
import NotFound from "../NotFound";
import SnackBar from "../Ui/SnackBar";
import FileUploader from "../Form/FileUploader";
import Loader from "../Ui/Loader";
import Firebase from "../../firebase";
import history from "../../history";

class App extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  state = {
    loading: true
  };

  componentWillMount() {
    Firebase.getCurrentUserRefThen(
      (user, doc) => {
        setTimeout(() => {
          this.setState({
            loading: false
          });
        }, 500);

        const data = doc.data();
        if (data.lastBoardId && this.props.location.pathname === "/") {
          history.push(`/home/${data.lastBoardId}`);
        }
      },
      () => {
        this.setState({
          loading: false
        });
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }
  onRouteChanged = () => {};

  render() {
    return (
      <div className="root">
        <Header />
        {(() => {
          if (this.state.loading) {
            return (
              <Loader loading={this.state.loading} overlay={true}></Loader>
            );
          } else {
            return (
              <AnimatedSwitch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0 }}
                atActive={{ opacity: 1 }}
                className="animatedswitch"
              >
                <Route exact path="/" component={Landing} />
                <Route exact path="/home/:id" component={Home} />
                <Route exact path="/settings/:id" component={Settings} />
                <Route exact path="/share/done" component={ShareDone} />
                <Route exact path="/share/:id" component={Share} />
                <Route
                  exact
                  path="/edit/:boardId/:articleId"
                  component={Editor}
                />
                <Route path="*" component={NotFound} />
              </AnimatedSwitch>
            );
          }
        })()}
        <SnackBar global={true} />
        <FileUploader />
      </div>
    );
  }
}

export default withRouter(App);
