import React from "react";
import $ from "jquery";

export default class {
  constructor() {
    this.scrollEvent = null;
  }

  genHeadingList = target => {
    const list = [];
    const refDict = new Map();
    const offset = -60;
    $(target)
      .find("h1,h2,h3")
      .not(".table-of-contents-container *")
      .each((index, elem) => {
        const onClick = () => {
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: $(elem).offset().top - 10 + offset
            },
            800
          );
        };

        const saveRef = ref => {
          refDict.set(elem, ref);
        };

        const reactComponent = React.createElement(
          elem.tagName.toLowerCase(),
          {
            key: index,
            onClick: onClick,
            ref: saveRef
          },
          $(elem).text()
        );
        list.push(reactComponent);
      });

    // スクロールイベント定義
    $(window).scroll(() => {
      // 可視範囲取得
      // const scrollTop = $(window).scrollTop();
      const windowHeight = $(window).height();
      // const scrollBottom = scrollTop +$(window).height();

      Array.from(refDict.entries()).some(elem => {
        elem = elem[0];
        const elemScrollTop = elem.getBoundingClientRect().top;
        if (elemScrollTop > 0 && elemScrollTop <= windowHeight) {
          // 色の初期化
          refDict.forEach((ref, elem) => {
            $(ref).removeClass("active");
          });
          // 色付け
          $(refDict.get(elem)).addClass("active");
          return true;
        } else {
          return false; // for es-lint
        }
      });
    });

    return list;
  };

  unbindEvent = () => {
    $(window).off("scroll");
  };
}
