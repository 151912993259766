import React, { Component } from "react";
import PropTypes from "prop-types";

import { DotLoader } from "react-spinners";
import { Transition } from "react-transition-group";
import classNames from "classnames";

/* styles */
const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

class Loader extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    overlay: PropTypes.bool,
    bg: PropTypes.bool
  };

  render() {
    const clsNames = classNames({
      overlay: this.props.overlay,
      bg: this.props.bg,
      loaderContainer: true
    });
    return (
      <Transition in={this.props.loading} timeout={duration}>
        {state => (
          <div
            className={`ui__loader ${clsNames}`}
            style={{
              ...defaultStyle,
              ...transitionStyles[state]
            }}
          >
            <DotLoader loading={this.props.loading} color={"#174491ee"} />
          </div>
        )}
      </Transition>
    );
  }
}

export default Loader;
