import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../history";
import Firebase from "../../firebase";
import SnackbarPlugin from "../../plugins/snackbar";

class Header extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  state = {
    photoURL: "",
    boardList: []
  };

  componentWillMount() {
    Firebase.auth.onAuthStateChanged(user => {
      Firebase.getCurrentUserRefThen(
        (ref, doc) => {
          const boardList = [];
          Firebase.forEach(ref.collection("boardList"), qs => {
            const data = qs.data();
            data.id = qs.id;
            boardList.push(data);
          }).then(() => {
            this.setState({
              boardList: boardList
            });
          });
          if (user) {
            this.setState({
              photoURL: user.photoURL
            });
          }
        },
        () => {}
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {}

  goToBoard(boardId) {
    history.push(`/home/${boardId}`);
  }

  doSignout() {
    const confirm = window.confirm("ログアウトしますか？");
    if (confirm) {
      Firebase.doSignOut(() => {
        history.push("/");
        SnackbarPlugin.openSnackBar("ログアウトしました");
        this.setState({
          photoURL: "",
          boardList: []
        });
      });
    }
  }

  render() {
    return (
      <nav
        className="navbar component"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="/">
            <img src="/img/logo.png" alt="logo" aria-label="go back to home" />
          </a>
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className="navbar-end">
          {(() => {
            if (this.state.boardList.length) {
              return (
                <div className="navbar-item has-dropdown is-hoverable">
                  <a className="navbar-link is-arrowless">
                    <i className="far fa-clipboard icon"></i>
                    ボードリスト
                  </a>
                  <div className="navbar-dropdown">
                    {(() => {
                      return this.state.boardList.map(board => {
                        return (
                          <a
                            className="navbar-item"
                            onClick={() => this.goToBoard(board.id)}
                            key={board.id}
                          >
                            {board.boardName}
                          </a>
                        );
                      });
                    })()}
                    <hr className="navbar-divider" />
                    <a
                      className="navbar-item"
                      onClick={() => {
                        history.push("/home/new");
                      }}
                    >
                      新しいボード
                    </a>
                  </div>
                </div>
              );
            }
          })()}
          <div className="navbar-item has-dropdown is-hoverable user-icon">
            <a className="navbar-link is-arrowless">
              <img src={this.state.photoURL}></img>
            </a>
            <div className="navbar-dropdown is-right">
              <a
                className="navbar-item"
                onClick={() => {
                  this.doSignout();
                }}
              >
                <i className="fas fa-sign-out-alt icon"></i>
                ログアウト
              </a>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}
export default withRouter(Header);
