import $ from "jquery";
class FileUploaderPlugin {
  constructor() {
    this.ref = null;
    this.afterUpload = () => {};
  }

  setRef = ref => {
    this.ref = ref;
  };

  onChange = () => {
    /* inputタグ変更時呼ばれる */
    this.afterUpload(this.ref.files);
    this.afterUpload = () => {}; // 初期化
  };

  doUpload = after => {
    // onChangeイベントに予約
    this.afterUpload = after;
    // なぜかjQueryでないと動かない
    // this.ref.dispatchEvent(new Event("click"));
    $(this.ref).click();
  };
}

export default new FileUploaderPlugin();
