import React, { Component } from "react";
import Input from "../Ui/Input";
import { withRouter } from "react-router";
import Firebase from "../../firebase";
import snackbar from "../../plugins/snackbar";
import history from "../../history";
import classNames from "classnames";

const urlRegx = /^(https?|ftp)(:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)$/;

class PinCreation extends Component {
  state = {
    url: "",
    errorForURL: ""
  };

  getBoardId() {
    return this.props.match.params.id;
  }

  componentWillReceiveProps(props) {
    if (props.show) {
      setTimeout(() => {
        this.setState({ url: "" });
        this.inputRef && this.inputRef.focus();
      }, 300);
    }
  }

  onCreateURLPin = () => {
    // バリデーション
    const errorForURL = !this.state.url.match(urlRegx);
    this.setState({
      errorForURL: errorForURL
    });
    // const errorForURLHeading = !Boolean(this.state.urlHeading);

    if (!errorForURL) {
      // 保存処理
      Firebase.createUrlPin(this.getBoardId(), {
        url: this.state.url
        // heading: this.state.urlHeading
      });
      this.props.onClose();
      snackbar.openSnackBar("Pinを作成しました");
    }
  };

  render() {
    const show = classNames({
      show: this.props.show,
      hidden: !this.props.show
    });
    return (
      <div className={`component pin-creation ${show}`}>
        <div className="is-modal has-text-centered">
          <h1 className="title">作成するPinのURLを入力してください</h1>
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder="URLを入力..."
              value={this.state.url}
              onChange={e => this.setState({ url: e.target.value })}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  this.setState({ url: e.target.value });
                  this.onCreateURLPin();
                }
              }}
              ref={ref => (this.inputRef = ref)}
            />
          </div>
          {(() => {
            if (this.state.errorForURL) {
              return (
                <p className="help is-danger">URLを正しく入力してください</p>
              );
            }
          })()}
          <button
            className="button is-info create-pin is-medium"
            onClick={() => this.onCreateURLPin()}
          >
            URLピンを作成
          </button>
        </div>
        <div
          className="close"
          onClick={() => {
            this.props.onClose && this.props.onClose();
          }}
        >
          <i className="fas fa-times icon" />
        </div>
      </div>
    );
  }
}

export default withRouter(PinCreation);
