import React, { Component } from "react";
import Firebase from "../../firebase";
import history from "../../history";

class Setting extends Component {
  state = {
    boardName: "",
    boardRef: null,
    allowList: [],
    unAuthorizedShareRequest: [],
    allowIdList: [],
    myUserId: null,
    owner: null
  };

  getBoardId(props) {
    return props ? props.match.params.id : this.props.match.params.id;
  }

  componentWillMount() {
    this.setState({
      myUserId: Firebase.get_currentUserId()
    });
    Firebase.getBoardDataThen(this.getBoardId(), data => {
      this.setState({
        boardName: data.boardName,
        allowIdList: data.allowList,
        owner: data.owner
      });
    });
    const boardRef = Firebase.genBoardRef(this.getBoardId());
    this.setState({
      boardRef: boardRef
    });
    const allowList = [];
    Firebase.forEach(boardRef.collection("allowList"), doc => {
      const data = doc.data();
      data.id = doc.id;
      allowList.push(data);
    }).then(() => {
      this.setState({
        allowList: allowList
      });
    });

    const unAuthorizedShareRequest = [];
    Firebase.forEach(boardRef.collection("shareRequestUsers"), doc => {
      const data = doc.data();
      data.id = doc.id;
      unAuthorizedShareRequest.push(data);
    }).then(() => {
      this.setState({
        unAuthorizedShareRequest: unAuthorizedShareRequest
      });
    });
  }

  toggleUser(uid, userData, allow) {
    /* ユーザーのアクセス許認可状態を変化させる*/
    if (allow) {
      if (this.state.unAuthorizedShareRequest.includes(userData)) {
        /* シェアリクエストから削除 */
        this.state.boardRef
          .collection("shareRequestUsers")
          .doc(userData.id)
          .delete();
      }
      if (!this.state.allowIdList.includes(userData.id)) {
        this.state.allowIdList.push(userData.id);
        this.state.boardRef.get().then(doc => {
          this.state.boardRef.update({
            allowList: doc.data().allowList.concat([userData.id])
          });
        });
      }
      /* アローリストに追加 */
      this.state.boardRef
        .collection("allowList")
        .doc(userData.id)
        .set(Object.assign(userData, { allow: true }));
    } else {
      /* アローリストから削除 */
      this.state.boardRef
        .collection("allowList")
        .doc(userData.id)
        .update({
          allow: false
        });
    }
  }

  downloadBoardData() {
    Firebase.getPinsThen(this.getBoardId(), pins => {
      const jsonData = {
        urls: {},
        documents: {}
      };
      pins.forEach(pin => {
        if (pin.type === "url") {
          jsonData["urls"][pin.heading] = pin.url;
        } else if (pin.type === "document") {
          pin.documentRef.get().then(doc => {
            const content = doc.data().content;
            if (content) {
              const formatted = content.replace(/\r?\n/g, "");
              const htmlCode = ` <html><head></head><body>${formatted}</body></html>`;
              jsonData["documents"][pin.heading] = htmlCode;
            }
          });
        }
      });

      setTimeout(() => {
        const blob = new Blob([JSON.stringify(jsonData, null, "  ")], {
          type: "application/json"
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "pinpin.json";
        link.click();
        URL.revokeObjectURL(url);
      }, 3000);
    });
  }

  render() {
    return (
      <div className="page settings">
        <section className="hero is-small title-hero">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">{this.state.boardName} の設定</h1>
            </div>
          </div>
        </section>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">アクセス権限</h1>
              <div className="table">
                <div className="columns heading">
                  <div className="column is-5">アカウント</div>
                  <div className="column is-5">メールアドレス</div>
                  <div className="column is-2">アクセス許可</div>
                </div>
                {(() => {
                  return this.state.allowList.map(userData => {
                    const uid = userData.id;
                    return (
                      <div className="columns" key={uid}>
                        <div className="column is-5">
                          {userData.displayName}
                        </div>
                        <div className="column is-5">{userData.email}</div>
                        <div className="column is-2">
                          <div className="field">
                            <input
                              id={uid}
                              type="checkbox"
                              name="switchOutlinedInfo"
                              className="switch is-outlined is-info"
                              defaultChecked={userData.allow}
                              disabled={
                                uid === this.state.myUserId ||
                                uid === this.state.owner
                              }
                              onClick={e => {
                                this.toggleUser(
                                  uid,
                                  userData,
                                  e.target.checked
                                );
                              }}
                            />
                            <label htmlFor={uid}></label>
                          </div>
                        </div>
                      </div>
                    );
                  });
                })()}
                {(() => {
                  return this.state.unAuthorizedShareRequest.map(userData => {
                    const uid = userData.id;
                    return (
                      <div className="columns" key={uid}>
                        <div className="column is-5">
                          {userData.displayName}
                        </div>
                        <div className="column is-5">{userData.email}</div>
                        <div className="column is-2">
                          <div className="field">
                            <input
                              id={uid}
                              type="checkbox"
                              name="switchOutlinedInfo"
                              className="switch is-outlined is-info"
                              onClick={e => {
                                this.toggleUser(
                                  uid,
                                  userData,
                                  e.target.checked
                                );
                              }}
                            />
                            <label htmlFor={uid}></label>
                          </div>
                        </div>
                      </div>
                    );
                  });
                })()}
              </div>
            </div>
          </div>
        </section>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">ダウンロード</h1>
              <button
                className="button is-medium"
                onClick={() => this.downloadBoardData()}
              >
                JSONでダウンロード
              </button>
            </div>
          </div>
        </section>
        <div
          className="back-button"
          onClick={() => {
            history.push(`/home/${this.getBoardId()}`);
          }}
        >
          <i className="fas fa-chevron-left icon"></i>
          戻る
        </div>
      </div>
    );
  }
}

export default Setting;
