import React from "react";
import ContentEditable from "react-contenteditable";
import Firebase from "../../firebase";
import firebase from "firebase";
import {
  // Editor,
  EditorState,
  SelectionState,
  // RichUtils,
  // Modifier,
  convertToRaw,
  DefaultDraftBlockRenderMap
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import PropTypes from "prop-types";
import {
  Decorator,
  BlockRenderMap,
  BlockRenderers,
  BlockStyleFn,
  EntityStyleFn,
  StyleMap,
  StyleMapForHTML,
  CustomBlockFn,
  CustomInlineFn
} from "./Modifiers";
import {
  EditorPlugins,
  SideToolbar,
  InlineToolbar,
  renderSideToolbarButtons,
  renderInlineToolbarButtons,
  ImagePluginManager
} from "./Plugins";
import EditorFooter from "./EditorFooter";
import Modules from "../../plugins/modules";
import history from "../../history";
import TableOfContents from "./TableOfContents";
import Scraper from "./scraper";
import SnackBar from "../Ui/SnackBar";
import SnackBarPlugin from "../../plugins/snackbar";
import queryString from "query-string";

class MyEditor extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };

  state = {
    editorState: EditorState.createEmpty(Decorator),
    extendedBlockRenderMap: DefaultDraftBlockRenderMap.merge(BlockRenderMap),
    initialized: false,
    title: "",
    content: "",
    saving: false,
    headingList: [],
    pinRef: null,
    sharing: false, // 共有モード
    showShareBalloon: false,
    shareURL: "",
    allowSharing: false
  };

  constructor() {
    super();
    this.snackBar = null;
    this.titleInputRef = null;
  }

  getBoardId() {
    return this.props.match.params.boardId;
  }

  componentWillMount = () => {
    if (!this.isNewArticle) {
      this.init();
    } else {
      this.isNewArticle = false;
    }
  };

  componentWillUnmount = () => {
    /* 目次追付イベントのアンバインド */
    this.scraper.unbindEvent();
    /* 目次生成イベントの削除 */
    clearTimeout(this.tableOfContentGeneratingEvent);
    /* メタイベントのアンバインド */
    this.unSetUp();
  };

  componentDidMount = () => {
    setTimeout(() => {
      /* 共有モードの時には画像フォーカスを阻止 */
      if (this.state.sharing) {
        window.document.querySelectorAll(".editor img").forEach(elm => {
          elm.style.pointerEvents = "none";
        });
      }
    }, 1000);
  };

  init = () => {
    this.ref = null;
    this.saveEvent = null; // 遅延保存用イベントプロパティ
    this.numOfSave = 0;
    this.isNewArticle = false;
    this.scraper = new Scraper();
    this.tableOfContentGeneratingEvent = null;
    this.unLockHistoryBlocking = null;
    this.myUserId = Firebase.get_currentUserId();

    // 共有URLか否か
    this.setState({
      sharing: queryString.parse(this.props.location.search).usp === "sharing"
    });

    // setting My Plugins
    ImagePluginManager.setContext(this);
    this.genRef();
    this.publishTableOfContentGeneratingEvent();
    this.setUp();
  };

  setUp = () => {
    /* 関連イベントのセットアップをする */
    // オフライン時オフラインスナックバーを出す
    window.addEventListener("offline", this.offLine);
    window.addEventListener("online", this.onLine);
  };
  unSetUp = () => {
    /* 最初期化処理 */
    window.removeEventListener("offline", this.offLine);
    window.removeEventListener("online", this.onLine);
    this.unLockHistoryBlocking && this.unLockHistoryBlocking();
  };

  onLine = () => {
    /* オンライン状態になった時の処理*/
    this.snackBar.closeSnackbar();
    this.unLockHistoryBlocking && this.unLockHistoryBlocking();
  };
  offLine = () => {
    /* オンライン状態になった時の処理*/
    this.snackBar && this.snackBar.openSnackBar("オフラインです", 0);
    this.unLockHistoryBlocking = history.block(
      "保存されてないデータは破棄されます。よろしいですか？"
    );
  };

  generateNewArticle = () => {
    /* 記事の新規作成 */
    Firebase.getCurrentUserRefThen(
      (userRef, doc) => {
        /* ボードにドキュメントPinを生成 */
        const title = queryString.parse(this.props.location.search).title || "";
        // if (!title) {
        //   return history.push("/page-404");
        // }
        this.setState({ title: title });
        Firebase.createDocumentPin(this.getBoardId(), {
          heading: title,
          documentRef: this.ref,
          documentId: this.ref.id
        }).then(pinRef => {
          this.setState({
            pinRef: pinRef
          });
          this.ref.set({
            user: userRef,
            regDate: firebase.firestore.Timestamp.now(),
            updateDate: firebase.firestore.Timestamp.now(),
            title: title,
            content: "",
            author: doc.data().displayName,
            view: 0,
            pinRef: pinRef
          });
        });

        // URLを差し替えておく
        history.replace(`/edit/${this.getBoardId()}/${this.ref.id}`);
        this.initEditor();
        setTimeout(() => {
          this.titleInputRef.el.current.focus();
        }, 500);
      },
      () => {
        /* Not Logined */
        history.push("/page-404");
      }
    );
  };

  genRef = () => {
    /* 記事情報取得 */
    this.isNewArticle = this.props.match.params.articleId === "new";
    this.ref = Firebase.getArticleRef(
      this.getBoardId(),
      this.isNewArticle ? null : this.props.match.params.articleId
    );

    if (!this.isNewArticle) {
      /* 既存 */
      this.getContent(this.initEditor);
    } else {
      /* 新規作成 */
      this.generateNewArticle();
    }
  };

  getContent = then => {
    Firebase.getData(
      this.ref,
      data => {
        this.setState({
          title: data.title,
          content: data.content,
          pinRef: data.pinRef,
          allowSharing: data.shared
        });
        then && then();
      },
      option => {
        option.history.push("/article-404");
      }
    );
  };

  getSelection() {
    const selection = this.state.editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const anchorOffset = selection.getAnchorOffset();
    const focusOffset = selection.getFocusOffset();
    const focusKey = selection.getFocusKey();
    const anchorBlockIndex = this.state.editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex(k => k === anchorKey);
    const focusBlockIndex = this.state.editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex(k => k === focusKey);
    return {
      anchorKey,
      anchorOffset,
      focusKey,
      focusOffset,
      anchorBlockIndex,
      focusBlockIndex
    };
  }

  initEditor = () => {
    // HTMLから描画コンテンツ化
    const contentState = stateFromHTML(this.state.content, {
      customBlockFn: CustomBlockFn,
      customInlineFn: CustomInlineFn
    });

    var editorState = EditorState.push(this.state.editorState, contentState);
    // 束縛
    this.setState({
      editorState: editorState,
      initialized: true
    });

    // 共有URLの取得
    const shareURL = `${window.location.host}/edit/${this.getBoardId()}/${
      this.ref.id
    }/?usp=sharing`;
    this.setState({
      shareURL: shareURL
    });

    // リアルタイム共有設定
    Firebase.onSnapshot(
      this.ref,
      data => {
        // 自分の更新なら無視
        if (data.lastModifiedBy === this.myUserId) {
          return;
        }

        // get current selection Info
        const selectionInfo = this.getSelection();

        // Set Data
        this.setState({
          title: data.title,
          content: data.content,
          allowSharing: data.shared
        });

        // create new contentState
        const contentState = stateFromHTML(data.content, {
          customBlockFn: CustomBlockFn,
          customInlineFn: CustomInlineFn
        });
        var editorState = EditorState.push(
          this.state.editorState,
          contentState
        );

        // 対応するブロックを探す
        const keySeq = editorState
          .getCurrentContent()
          .getBlockMap()
          .keySeq();
        const length = keySeq.count();
        const anchorKey = keySeq.find(
          (_, i) => i === selectionInfo.anchorBlockIndex || i === length
        );
        const focusKey = keySeq.find(
          (_, i) => i === selectionInfo.focusBlockIndex || i === length
        );

        // 選択領域の復元
        const newSelection = editorState.getSelection().merge({
          anchorKey,
          anchorOffset: selectionInfo.anchorOffset,
          focusKey,
          focusOffset: selectionInfo.focusOffset
        });

        // 束縛
        this.setState({
          // editorState: editorState
          editorState: EditorState.forceSelection(editorState, newSelection) // selectionを合成して保存
        });
      },
      option => {
        option.history.push("/article-404");
      }
    );
  };

  onChangeTitle = e => {
    // save
    var value = Modules.removeHtmlTag(e.target.value.trim());
    this.ref.set(
      {
        title: value || "無題"
      },
      { merge: true }
    );
    // pinにも反映
    this.state.pinRef.set(
      {
        heading: value
      },
      { merge: true }
    );
    // update state
    this.setState({
      title: value
    });
  };

  onChangeContent = editorState => {
    /* HTML化してデータの保存 */
    const currentContent = editorState.getCurrentContent();
    if (editorState.getLastChangeType() === null) {
      /* 内容に差分が発生していないなら中断 */
      return;
    }

    /* 更新プロセス */
    this.setState({
      editorState
    });

    /* 初期化処理 */
    if (this.state.initialized) {
      // 走っている遅延イベントがあれば止める
      if (this.saveEvent) {
        clearTimeout(this.saveEvent);
      } else {
        this.setState({
          saving: true
        });
      }
      // 遅延保存処理
      this.saveEvent = setTimeout(() => {
        const htmlContent = stateToHTML(currentContent, {
          inlineStyles: StyleMapForHTML,
          entityStyleFn: EntityStyleFn,
          blockRenderers: BlockRenderers
        });
        // console.log(convertToRaw(currentContent));
        // console.log(htmlContent);
        // TODO: 将来版管理
        // データ吹っ飛んで泣いたので取り急ぎのバックアップ処理
        if (this.numOfSave++ && this.numOfSave % 12 === 0) {
          this.ref.set(
            {
              backup: htmlContent
            },
            { merge: true }
          );
        }
        /* データ保存 */
        this.ref.set(
          {
            content: htmlContent,
            lastModifiedBy: this.myUserId
          },
          { merge: true }
        );
        this.setState({
          saving: false
        });
        this.saveEvent = null;
      }, 100);
    }

    /* 目次生成イベントの発行 */
    this.publishTableOfContentGeneratingEvent();
  };
  onChange = this.onChangeContent; // Alias for ImagePluginManager

  publishTableOfContentGeneratingEvent(immediate) {
    /* 目次生成イベントをタイムアウト付きで発行 */
    if (immediate) {
      this.setState({
        headingList: this.scraper.genHeadingList(".editor .main-content")
      });
    } else {
      clearTimeout(this.tableOfContentGeneratingEvent);
      this.tableOfContentGeneratingEvent = setTimeout(() => {
        this.setState({
          headingList: this.scraper.genHeadingList(".editor .main-content")
        });
      }, 3500);
    }
  }

  doShare(share) {
    this.ref.update({
      shared: share
    });
    this.setState({
      allowSharing: share
    });
    if (share) {
      SnackBarPlugin.openSnackBar("共有リンクをONにしました");
    } else {
      SnackBarPlugin.openSnackBar("共有リンクをOFFにしました");
    }
  }

  render() {
    return (
      <div className="editor">
        <SnackBar
          position="top"
          type="error"
          ref={ref => {
            this.snackBar = ref;
          }}
        ></SnackBar>
        <div className="header-section">
          <ContentEditable
            className="header"
            data-text="ドキュメントのタイトル"
            onChange={this.onChangeTitle}
            html={this.state.title}
            disabled={this.state.sharing}
            ref={ref => {
              this.titleInputRef = ref;
            }}
          />
          {(() => {
            if (!this.state.sharing) {
              const balloonStyle = {
                visibility: this.state.showShareBalloon ? "visible" : "hidden"
              };
              return (
                <div className="balloon-container">
                  <button
                    className="button print-button tooltip"
                    onClick={() => {
                      this.setState({
                        showShareBalloon: true
                      });
                      if (!this.state.showShareBalloon) {
                        setTimeout(() => {
                          this.setState({
                            showShareBalloon: false
                          });
                        }, 6000);
                      }
                      if (!this.state.allowSharing) {
                        this.doShare(true);
                      }
                    }}
                    data-tooltip="ドキュメントを共有"
                  >
                    <i className="fas fa-link"></i>
                  </button>
                  <div className="balloon" style={balloonStyle}>
                    <div className="row">
                      <p>リンクの共有</p>
                      <div className="field">
                        <input
                          id="fafafa"
                          type="checkbox"
                          name="switchOutlinedInfo"
                          className="switch is-outlined is-info"
                          onClick={e => {
                            this.doShare(e.target.checked);
                          }}
                          checked={this.state.allowSharing}
                        />
                        <label htmlFor={"fafafa"}></label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="field has-addons">
                        <p className="control">
                          <input
                            className="input"
                            type="text"
                            placeholder=""
                            readOnly
                            value={this.state.shareURL}
                          />
                        </p>
                        <p className="control">
                          <a
                            className="button"
                            onClick={() => {
                              if (navigator.clipboard) {
                                navigator.clipboard.writeText(
                                  this.state.shareURL
                                );
                              }
                              SnackBarPlugin.openSnackBar(
                                "共有リンクをクリップボードにコピーしました"
                              );
                            }}
                          >
                            <i className="far fa-clipboard"></i>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              const style = {
                marginRight: 10
              };
              return (
                <span className="tag is-primary" style={style}>
                  読み込み専用
                </span>
              );
            }
          })()}
          <button
            className="button print-button tooltip"
            onClick={() => {
              window.print();
            }}
            data-tooltip="印刷"
          >
            <i className="fas fa-print"></i>
          </button>
        </div>
        <div className="main-content content">
          <Editor
            customStyleMap={StyleMap}
            editorState={this.state.editorState}
            onChange={this.onChangeContent}
            placeholder="ドキュメントの本文"
            plugins={EditorPlugins}
            blockStyleFn={BlockStyleFn}
            blockRenderMap={this.state.extendedBlockRenderMap}
            readOnly={this.state.sharing}
          />
        </div>
        <SideToolbar>{renderSideToolbarButtons}</SideToolbar>
        <InlineToolbar>{renderInlineToolbarButtons}</InlineToolbar>
        <EditorFooter
          editorState={this.state.editorState}
          saving={this.state.saving}
        />
        <TableOfContents headingList={this.state.headingList}></TableOfContents>
        {(() => {
          if (!this.state.sharing) {
            return (
              <div
                className="back-button"
                onClick={() => {
                  history.push(`/home/${this.getBoardId()}`);
                }}
              >
                <i className="fas fa-chevron-left icon"></i>
                戻る
              </div>
            );
          }
        })()}
      </div>
    );
  }
}

export default MyEditor;
